<template>
  <b-card class="mt-6">
    <b-card-text class="p-0 m-0">
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form
          @submit.stop.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
          class="p-0"
          autocomplete="off"
        >
          <b-row>
            <b-col cols="12" md="6">
              <v-select
                v-model="form.statu"
                :options="destekStatu"
                :reduce="(sabit) => sabit.value"
                label="title"
                :clearable="false"
                class="invoice-filter-select d-block"
              />
            </b-col>
            <b-col cols="12">
              <validation-provider
                name="Mesaj"
                :rules="{ required: true }"
                v-slot="validationContext"
              >
                <b-form-group>
                  <b-form-textarea
                    size="lg"
                    class="rounded-0"
                    ref="email"
                    rows="7"
                    v-model="form.mesaj"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-1-live-feedback"
                    placeholder="Mesaj"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <b-button
                squared
                block
                type="submit"
                size="lg"
                variant="primary"
                class="mr-2"
                :disabled="loaded"
              >
                <i class="fad fa-save pr-2" v-if="!loaded"></i>
                <i class="fad fa-spin fa-spinner" v-else></i>
                <span class="align-middle">Ekle</span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-text>
  </b-card>
</template>

<script>
import vSelect from "vue-select";
import { ref, computed, toRefs, defineComponent } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import store from "@/store";
export default defineComponent({
  components: { vSelect },
  props: {
    item: {
      type: Object,
      default: {},
      required: true,
    },
  },
  setup(props, cntxt) {
    const expo = {};
    const toast = useToast();

    const { item } = toRefs(props);

    expo.users = computed(() => store.getters["getUserData"]);

    expo.loaded = ref(false);

    expo.form = ref({
      ac_k_no: item.value.ac_k_no,
      destek_k_no: item.value.k_no,
      destek_turu: "temsilci",
      mesaj: null,
      ad_soyad: expo.users.value.name,
      statu: "cevaplandi",
    });

    expo.destekStatu = ref([
      {
        value: "yanit_bekliyor",
        title: "Yanıt Bekliyor",
      },
      {
        value: "cevaplandi",
        title: "Cevaplandı",
      },
      {
        value: "kapandi",
        title: "Kapandı",
      },
    ]);

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      expo.loaded.value = true;
      store.dispatch("destekHattiCevapEkle", expo.form.value).then((res) => {
        if (res.data.success) {
          toast.success("Kayıt Eklendi", {
            position: "top-right",
          });
          cntxt.emit("comment", {
            ...res.data.data,
            statu: expo.form.value.statu,
          });

          expo.form.value.mesaj = null;
        }
        expo.loaded.value = false;
      });
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
