<template>
  <div class="p-4">
    <template v-if="destek">
      <DestekDetayHeader :item="destek" />

      <div class="py-4">
        <div v-for="(item, index) in destek.mesajlar" :key="index">
          <DestekDetayItem
            :class="
              item.destek_turu == 'musteri'
                ? 'border-warning'
                : 'border-info ml-10'
            "
            :item="item"
          />
        </div>
      </div>

      <DestekDetayCevap
        :item="destek"
        id="comment-add"
        @comment="handlerNewComment($event)"
      />
    </template>
    <template v-else>
      <b-alert variant="warning">
        <h5>Uyarı !</h5>
        <p>Gösterilecek veri yok.</p>
      </b-alert>
    </template>
  </div>
</template>

<script>
import store from "@/store";
import { ref, computed, defineComponent } from "@vue/composition-api";
import DestekDetayHeader from "./component/DestekDetayHeader";
import DestekDetayItem from "./component/DestekDetayItem";
import DestekDetayCevap from "./component/DestekDetayCevap";
import { useRouter } from "@/libs/utils";
export default defineComponent({
  components: { DestekDetayHeader, DestekDetayItem, DestekDetayCevap },
  setup() {
    const expo = {};
    const { route } = useRouter();

    expo.destek = ref(null);

    expo.handlerNewComment = (event) => {
      expo.destek.value.mesajlar.push(event);
      expo.destek.value.statu = event.statu;
      expo.destek.value.edit_date = event.save_date;
    };

    const handlerFetchData = () => {
      const k_no = route.value.params.k_no;
      if (k_no) {
        store.dispatch("destekHattiFindOne", k_no).then((res) => {
          if (res.data.success) {
            expo.destek.value = res.data.data;
          }
        });
      } else {
      }
    };

    handlerFetchData();

    return { ...expo };
  },
});
</script>

<style scoped></style>
