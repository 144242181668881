<template>
  <b-card>
    <b-card-text class="p-0 m-0">
      <table class="table table-bordered">
        <thead>
          <tr class="font-weight-bold">
            <th scope="col" width="15%"># Talep No</th>
            <th scope="col" width="15%">Üye | Acemte</th>
            <th scope="col">Konu</th>
            <th scope="col" width="15%">Durum</th>
            <th scope="col" width="25%">Oluşturma Güncelleme Tarihi</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">{{ item.talep_no }}</th>
            <td>
              <template v-if="item.ac_k_no">
                <b-badge
                  variant="info"
                  class="d-block mt-1 rounded-0 text-left"
                >
                  {{ item.acente.firma_adi }}
                </b-badge>
              </template>
              <template v-else>
                <b-badge
                  variant="warning"
                  class="d-block mt-1 rounded-0 text-left"
                >
                  {{ item.uye.ad }} {{ item.uye.soyad }}
                </b-badge>
              </template>
            </td>
            <td>{{ item.konu }}</td>
            <td>{{ statuText(item.statu) }}</td>
            <td>
              <b-badge variant="warning" class="mr-2">
                {{ item.save_date | momentText }}
              </b-badge>
              <b-badge variant="info">
                {{ item.edit_date | momentText }}
              </b-badge>
            </td>
          </tr>
        </tbody>
      </table>
    </b-card-text>
  </b-card>
</template>

<script>
import {
  ref,
  computed,
  defineComponent,
  useContext,
} from "@vue/composition-api";
export default defineComponent({
  props: {
    item: {
      type: Object,
      default: {},
      required: true,
    },
  },
  setup() {
    const expo = {};

    expo.statuText = computed(() => {
      const statusText = {
        yanit_bekliyor: "Yanıt Bekliyor",
        cevaplandi: "Cevaplandı",
        kapandi: "Kapandı",
      };
      return (status) => statusText[status];
    });
    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
