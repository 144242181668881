<template>
  <b-media class="border p-4 mt-2">
    <template #aside>
      <b-avatar
        squared
        rounded
        :text="commentTitle(item.cevaplayan)"
        size="4em"
        class="mr-4"
        :variant="item.destek_turu == 'musteri' ? 'warning' : 'info'"
      />
    </template>
    <div class="d-flex justify-content-between align-items-center">
      <h5 class="mt-0">
        {{ yazarText(item.destek_turu) }} : {{ item.cevaplayan }}
      </h5>
      <b-badge variant="info">
        {{ item.save_date | momentText }}
      </b-badge>
    </div>
    <p>{{ item.mesaj }}</p>
  </b-media>
</template>

<script>
import { ref, computed, defineComponent } from "@vue/composition-api";
export default defineComponent({
  props: {
    item: {
      type: Object,
      default: {},
      required: true,
    },
  },
  setup() {
    const expo = {};

    expo.commentTitle = computed(() => {
      return (val) => {
        let name = "";
        const title = val.split(" ");
        title.forEach((element) => {
          name += element.substring(0, 1);
        });
        return name;
      };
    });

    expo.yazarText = computed(() => {
      const statusText = {
        musteri: "Müşteri",
        temsilci: "Temsilci",
      };
      return (status) => statusText[status];
    });

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
